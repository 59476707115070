<script setup lang="ts">
import dayjs from "dayjs";

const { t } = useT();

const {
	public: { baseImageUrl }
} = useRuntimeConfig();

const { data: appInitData } = useAppInitData();

const { isSweepStakes } = useSwitchMode();
const { open } = useTaoModals();

const {
	entriesSum,
	roundsPlayed,
	leaders,
	coinsSum,
	tournamentData,
	activeStatus,
	subscribedStatus,
	loadingSubscribtions,
	durationLeft,
	handleSubscribe,
	handleOpenPopup
} = useTournamentData({ open });

const { game } = useGameModal();

const loading = ref(false);

const isTournamentGame = computed(() => {
	if (game.value && game.value?.game) {
		return tournamentData.value?.isTournamentGame;
	}
	return false;
});

const showBtn = computed(() => (activeStatus.value && !isTournamentGame.value) || !subscribedStatus.value);

const handleIngameSubscribe = async () => {
	if (!subscribedStatus.value) {
		await handleSubscribe({
			id: tournamentData.value?.id || 0,
			isSub: subscribedStatus.value
		});
		return;
	}
	if (!isTournamentGame.value) {
		open("LazyOModalTournamentGames");
	}
};

const fetchData = async () => {
	loading.value = true;
	await new Promise((resolve) => setTimeout(resolve, 2000));
	loading.value = false;
};

onMounted(() => {
	fetchData();
});

watch(isSweepStakes, () => {
	fetchData();
});
</script>

<template>
	<div class="tournament-sidebar">
		<AText class="color-gray-200" :modifiers="['center', 'uppercase']" as="div">{{ t("Free to play") }}</AText>
		<NuxtIcon name="14/info" class="info-icon" data-tid="open-info" @click="open('LazyOModalTournamentHowItWorks')" />
		<ABadge :background="isSweepStakes ? 'var(--cabimas)' : 'var(--chengdu)'" variant="info">
			<AText :size="18" :class="isSweepStakes ? 'text-chizhou' : 'text-chofu'" :modifiers="['semibold', 'uppercase']">{{
				isSweepStakes ? t("Sc") : t("Tc")
			}}</AText>
			<AText :class="isSweepStakes ? 'text-chizhou' : 'text-chofu'" :modifiers="['uppercase']">
				{{ t("Mode") }}
			</AText>
		</ABadge>

		<div class="sidebar-content">
			<AText class="sidebar-title" :modifiers="['bold', 'center', 'uppercase']" as="div">
				{{ tournamentData?.title }}
			</AText>

			<AText :size="12">
				<i18n-t v-if="activeStatus" keypath="Ends in {key}">
					<template #key>
						<b class="timer-left">{{ durationLeft }}</b>
					</template>
				</i18n-t>
				<i18n-t v-else keypath="Starts: {key}" tag="span">
					<template #key>
						<b>{{ dayjs(tournamentData?.start).format("h:mm A") }}</b>
					</template>
				</i18n-t>
			</AText>
			<div class="top">
				<NuxtImg
					:src="`${baseImageUrl}${tournamentData?.image}`"
					class="tournament-img"
					format="avif"
					loading="lazy"
					width="160"
					height="112"
					alt="Tournament image"
				/>
				<AText :size="12" class="mb-1" :modifiers="['bold', 'center', 'uppercase']">
					{{ t("Prize Pool:") }}
				</AText>

				<div class="prizes mb-2">
					<MPrizeFund v-if="entriesSum" icon="12/secret-coins" variant="entries" :iconSize="24">
						<AText :size="28" :modifiers="['bold']">
							{{ numberFormat(entriesSum) }}
						</AText>
					</MPrizeFund>
					<MPrizeFund v-if="coinsSum" icon="12/coins" variant="coins" :iconSize="24">
						<AText class="color-gray-50" :size="28" :modifiers="['bold']">
							{{ numberFormat(coinsSum) }}
						</AText>
					</MPrizeFund>
				</div>

				<AText class="mb-1" :size="12">
					<i18n-t keypath="Min play level: {key}" tag="span">
						<template #key>
							<b> {{ tournamentData?.betMin }}</b>
						</template>
					</i18n-t>
				</AText>

				<AText :size="12">
					<i18n-t v-if="activeStatus && subscribedStatus && !!roundsPlayed" keypath="Plays: {key1}/{key2}" tag="span">
						<template #key1>
							<b>{{ roundsPlayed }}</b>
						</template>
						<template #key2>
							<b>{{ tournamentData?.betLimit }}</b>
						</template>
					</i18n-t>
					<i18n-t v-else keypath="Plays {key}" tag="span">
						<template #key>
							<b>{{ tournamentData?.betLimit }}</b>
						</template>
					</i18n-t>
				</AText>
			</div>

			<ul v-if="subscribedStatus && leaders.length" class="leaderboard-list">
				<OTournamentsTableItem
					v-for="(leader, index) in leaders"
					:key="index"
					:userName="leader.name"
					:userActive="leader.profile === appInitData?.profile"
					:rank="leader.position"
					:prizePlace="leader.prizePlace"
					:points="leader.points"
					:userLoc="leader.city"
					class="leaderboard-item"
				/>
			</ul>

			<AText
				class="show-button"
				data-tid="show-prizes"
				:modifiers="['link', 'underline']"
				@click="handleOpenPopup(activeStatus)"
			>
				{{ activeStatus ? t("Show leaderboard") : t("Show prizes") }}
			</AText>

			<AButton
				v-if="showBtn && !loading"
				size="s"
				data-tid="join-tournament"
				variant="primary"
				:modifiers="['wide']"
				@click="handleIngameSubscribe()"
			>
				<AAnimationSpin v-if="loadingSubscribtions">
					<NuxtIcon class="preloader-icon" name="24/loader" filled />
				</AAnimationSpin>
				<AText v-else :modifiers="['uppercase', 'bold']">
					{{ subscribedStatus ? t("Switch to the Tournament") : t("Join now") }}
				</AText>
			</AButton>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.tournament-sidebar {
	position: relative;
	max-width: 100%;
	width: 328px;
	border-radius: 16px;
	padding: 16px;
	background: var(--gray-900);
	display: flex;
	flex-direction: column;
	gap: 16px;
	position: relative;

	.sidebar-content {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 8px;
		position: relative;
	}

	&:deep(.badge) {
		position: absolute;
		top: 0;
		right: 0;
		width: 56px;
		height: 47px;
		border-radius: 0 16px 0 16px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		border: none;
		padding: 8px;
	}

	.timer-left {
		text-transform: lowercase;
	}

	.info-icon {
		position: absolute;
		cursor: pointer;
		top: 16px;
		left: 16px;
		color: var(--gray-50);
	}

	.top {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		border-radius: 10px;
		background: var(--gray-800);
		padding: 16px;
	}

	.prizes {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.show-button {
		margin: 8px 0;
	}

	.leaderboard-list {
		width: 100%;
		display: flex;
		flex-direction: column;
		gap: 4px;
	}

	.leaderboard-item:deep(.cell) {
		&.right {
			justify-content: flex-end;
		}
	}

	.sidebar-title {
		max-width: 150px;
	}

	.tournament-img {
		width: 140px;
		height: 80px;
		margin-bottom: 16px;
	}
}
</style>
